// RecordButton.tsx

import React, { useEffect, useState } from 'react';
import './styles.css';
import {
  a,
  useSpring,
  useSpringRef,
  useChain,
  config
} from '@react-spring/web';

interface RecordButtonProps {
  disableInstructions: boolean;
  toggleRecording: (isRecordingValue: boolean) => void;
}

/**
 * @todo:
 * - update to start recording video when animatedLine starts, otherwise take an image
 */
const RecordButton: React.FC<RecordButtonProps> = ({
  disableInstructions,
  toggleRecording
}) => {
  const [toggle, setToggle] = useState(false);

  const animatedLineRef = useSpringRef();
  const animatedStrokeRef = useSpringRef();

  /** max time in seconds */
  const maxRecordTime: number = 15;

  /** actual length is 269.xx, which will show part of the stroke.
   *  rounding up removes the dot that shows.
   */
  const roundedLength = 270;

  const animatedLine = useSpring({
    ref: animatedLineRef,
    strokeDashoffset: toggle ? 0 : roundedLength,
    strokeDasharray: roundedLength,
    config: {
      duration: maxRecordTime * 1000
    },
    onRest: () => {
      toggleRecording(false);
    },
    immediate: !toggle // dont allow it to animate back to start
  });

  const animatedScale = useSpring({
    scale: toggle ? 1.2 : 1.1,
    rotate: -90
  });

  const animatedStrokeWidth = useSpring({
    ref: animatedStrokeRef,
    strokeWidth: toggle ? 18 : 0,
    r: toggle ? 34 : 27,
    opacity: toggle ? 0.4 : 0,
    immediate: !toggle,
    config: {
      duration: 200
    }
  });

  const handlePress = React.useCallback(
    (evt: React.PointerEvent<HTMLButtonElement>) => {
      setToggle(evt.type === 'pointerdown');
      toggleRecording(evt.type === 'pointerdown');
    },
    [toggleRecording]
  );


  useChain([animatedStrokeRef, animatedLineRef]);

  const helpTextStyle = useSpring({
    transform: 'translateY(-90%)',
    opacity: 1,
    from: {
      transform: 'translateY(-80%)',
      opacity: 0
    },
    config: config.gentle,
    delay: 1500
  });

  return (
    <div className="relative flex justify-center">
      {!disableInstructions && !toggle && (
        <a.div
          className="absolute bg-white/50 backdrop-blur-sm p-3 drop-shadow-lg w-[8rem] rounded-lg flex flex-col"
          style={helpTextStyle}
        >
          <span className="font-light text-ink1 text-[15px] leading-tight tracking-tight text-center">
            Press for photo
          </span>
          <span className="font-light text-ink1 text-[15px] leading-tight tracking-tight text-center">
            or hold for video
          </span>
        </a.div>
      )}

      <button
        className="relative select-none touch-none h-max w-max outline-1 outline-white/30 drop-shadow-sm active:drop-shadow-none rounded-full"
        onPointerDown={handlePress}
        onPointerUp={handlePress}
      >
        <a.svg
          className="pointer-events-none select-none text-white rounded-full"
          width="106"
          height="106"
          viewBox="0 0 90 90"
          xmlns="http://www.w3.org/2000/svg"
          style={animatedScale}
        >
          <g>
            <a.circle
              cx="45"
              cy="45"
              r={animatedStrokeWidth.r}
              strokeWidth={animatedStrokeWidth.strokeWidth}
              stroke="currentColor"
              fill="none"
              opacity={animatedStrokeWidth.opacity}
            />
            <circle
              cx="45"
              cy="45"
              r="25"
              stroke="currentColor"
              fill="currentColor"
              fillOpacity="0.66"
            />
            <circle cx="45" cy="45" r="27" fill="#FD4487" fillOpacity="0.1" />
            <a.circle
              id="circle"
              r="43"
              cy="45"
              cx="45"
              strokeWidth="3"
              strokeOpacity={toggle ? 1 : 0}
              strokeLinecap="round"
              stroke="#FD4487"
              fill="none"
              style={animatedLine}
            />
          </g>
        </a.svg>
      </button>
    </div>
  );
};

export default RecordButton;
