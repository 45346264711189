import { useState, type ReactNode } from 'react';
import { a, config, useSpring } from '@react-spring/web';

export function SlideUpDrawer({
  children,
  isPhoto
}: {
  children: ReactNode;
  isPhoto: boolean;
}) {
  const [height, setHeight] = useState<number | null>(null);
  const drawerStyle = useSpring({
    from: {
      translateY: height as number,
      scale: 0.75
    },
    translateY: 0,
    scale: 1,
    config: config.default
  });
  const bgOverlayStyle = useSpring({
    from: {
      opacity: 0
    },
    opacity: 1,
    config: config.stiff
  });
  const flashStyle = useSpring({
    from: {
      opacity: 1,
      zIndex: 2
    },
    opacity: 0,
    zIndex: -1
  });

  return (
    <div className="relative h-full w-full">
      <a.div
        className="absolute inset-0 bg-black/60 backdrop-blur-sm z-0"
        style={bgOverlayStyle}
      />
      {isPhoto && (
        <a.div
          id="flash"
          className="absolute h-full w-full bg-white"
          style={flashStyle}
        />
      )}

      <a.div
        className="absolute z-1 h-full w-full bg-white"
        style={drawerStyle}
        ref={(ref) => {
          // The ref is `null` on component unmount
          if (ref) {
            setHeight(ref.getBoundingClientRect().height);
          }
        }}
      >
        {children}
      </a.div>
    </div>
  );
}
