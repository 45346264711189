import * as React from 'react';
import { isMobile } from 'react-device-detect';
import useResizeObserver from 'use-resize-observer';
import * as Config from './Config';

const sources: {
  desktop: Record<string, string>;
  mobile: Record<string, string>;
} = Config.BG_VIDEO_SOURCE;

const posterImg = isMobile
  ? `https://tf-web-assets.imgix.net/images/${Config.MOBILE_POSTER_IMG}/mobile-poster.png?auto=format`
  : `https://tf-web-assets.imgix.net/images/${Config.MOBILE_POSTER_IMG}/desktop-poster.png?auto=format`;

export function VideoBg() {
  const { ref, width = 1 } = useResizeObserver<HTMLDivElement>();
  const [isMetadataLoaded, setIsMetadataLoaded] = React.useState(false);

  const handleLoadedMetadata = (
    e: React.SyntheticEvent<HTMLVideoElement, Event>
  ) => {
    setIsMetadataLoaded(true);
    const target: HTMLVideoElement = e.currentTarget;
    target.play();
  };

  const screenSources = isMobile ? sources.mobile : sources.desktop;

  // check the screen size and set the best video src size for best user experience
  // grabbed from VideoLoop - studio-octopus
  const sourceEntries = React.useMemo(() => {
    const entries = Object.entries(screenSources)
      .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
      .map((e, i) => [parseInt(e[0]), e[1]]);
    entries[entries.length - 1][0] = Infinity;
    return entries;
  }, [screenSources]);

  // grabbed from VideoLoop - studio-octopus
  const selectedSource = React.useMemo(() => {
    const bestSizeOption = sourceEntries.filter((entry) => {
      return width < entry[0];
    });
    return bestSizeOption[0][1];
  }, [sourceEntries, width]);

  return (
    <div
      ref={ref}
      className="absolute inset-0 h-full w-full bg-black opacity-30"
    >
      {Config.IS_VIDEO_AVAILABLE?
        (<video
          className="h-full w-full transition-opacity duration-500 ease-out-quart object-cover object-center"
          onLoadedMetadata={handleLoadedMetadata}
          poster={posterImg}
          preload="metadata"
          src={selectedSource as string}
          style={{ opacity: isMetadataLoaded ? 1 : 0 }}
          loop
          muted
          playsInline
        />):
        <img src={posterImg}></img>
      }
    </div>
  );
}
