import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.css";

import reportWebVitals from './reportWebVitals';


async function load() {
  await Promise.all([
    document.fonts.load('10pt "ABC Synt"'),
    document.fonts.load('italic 10pt "ABC Synt"'), // Assuming you use the italic style in your app
    document.fonts.load('300 10pt "Antique Legacy"'),
    document.fonts.load('400 10pt "Antique Legacy"'),
  ]).then(() => {
      // After all fonts are loaded, render your app
      const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
      );
      root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      );
  }).catch(error => console.log("Font loading error:", error));
  
}

load();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();