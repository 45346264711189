// StartButton.tsx

import React from 'react';
import './styles.css';

interface StartButtonProps {
  title: string;
  clickStartButton: () => void;
}

const StartButton: React.FC<StartButtonProps> = ({
  title,
  clickStartButton
}) => {
  return (
    <button className="start-button" onClick={clickStartButton}>
      <p className="start-button-text">{title}</p>
    </button>
  );
};

export default StartButton;
