// config.ts
export const TITLE = "Discover the MetaTABI";
export const INSTRUCTIONS = "Experience Maison Margiela's MetaTABI boots as monumental sculptures in Augmented Reality";
export const INSTRUCTIONS_DESKTOP = "Scan the QR code with your phone's camera to launch the MetaTABI sculpture.";
export const INSTRUCTIONS_MOBILE_TITLE = "Allow the site to use your camera"
export const INSTRUCTIONS_MOBILE = "We need permission to use the camera of your phone to show our garments in augmented reality.";
export const LENS_ID = "68a86828-9514-4db9-94c4-f6ae48b7e651"
export const LENS_GROUP_ID = "a91f2abd-ed64-4105-bcef-a523b816ecf8"
export const IS_BACK_FACING = true;
export const IS_SINGLE_CAMERA = true;
export const VIDEO_TITLE = "metatabi";
export const MOBILE_POSTER_IMG = "metatabi";
export const IS_VIDEO_AVAILABLE = false;

export const BG_VIDEO_SOURCE = {
    desktop: {
      1080: '',
      720: '',
      540: ''
    },
    mobile: {
      1080: '',
      720: '',
      540: '',
      360: ''
    }
  };