import * as React from 'react';

const iconMap = {
  back: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="x(3) 1">
        <path
          id="Vector"
          d="M3 12L21 12"
          stroke="#1E1E1E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M3 12L9 18"
          stroke="#1E1E1E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M3 12L9 5"
          stroke="#1E1E1E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ),
  sync: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M0.666748 2.66667V6.66667H4.66675"
        stroke="#1E1E1E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3333 13.3333V9.33333H11.3333"
        stroke="#1E1E1E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6601 5.99999C13.322 5.04452 12.7473 4.19026 11.9898 3.51694C11.2322 2.84362 10.3164 2.37317 9.32789 2.1495C8.33934 1.92583 7.31025 1.95622 6.33662 2.23784C5.363 2.51947 4.47658 3.04314 3.76008 3.75999L0.666748 6.66666M15.3334 9.33333L12.2401 12.24C11.5236 12.9569 10.6372 13.4805 9.66354 13.7621C8.68992 14.0438 7.66082 14.0742 6.67227 13.8505C5.68373 13.6268 4.76795 13.1564 4.01039 12.483C3.25284 11.8097 2.67819 10.9555 2.34008 9.99999"
        stroke="#1E1E1E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  share: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33329 3.33333V9C8.33329 9.18409 8.18405 9.33333 7.99996 9.33333C7.81586 9.33333 7.66663 9.18409 7.66663 9V3.33333H8.33329Z"
        fill="#1E1E1E"
      />
      <path d="M7.99996 2L9.33329 4H6.66663L7.99996 2Z" fill="#1E1E1E" />
      <path
        d="M10.25 5H10.5C11.6046 5 12.5 5.89543 12.5 7V11C12.5 12.1046 11.6046 13 10.5 13H5.5C4.39543 13 3.5 12.1046 3.5 11V7C3.5 5.89543 4.39543 5 5.5 5H5.75"
        stroke="#1E1E1E"
      />
    </svg>
  )
};

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: keyof typeof iconMap;
}

export function IconButton({ onClick, icon, ...props }: IconButtonProps) {
  return (
    <button
      className="inline-flex justify-center items-center rounded-full h-[48px] w-[48px] bg-[#fbfbfe]/80 border-[1px] border-[#fbfbfe] drop-shadow-lg backdrop-blur-md active:drop-shadow-none active:backdrop-brightness-0 active:backdrop-blur-none disabled:pointer-events-none disabled:opacity-0"
      onClick={onClick}
      {...props}
    >
      {iconMap[icon]}
    </button>
  );
}
