// DesktopFallback.tsx

import * as React from 'react';
import './styles.css';
import MobileIntroLogos from './MobileIntroLogos';
import DesktopFallbackQRCode from './DesktopFallbackQRCode';
import { FallbackContainer } from './FallbackContainer';

interface DesktopFallbackProps {
  title: string;
  instructions: string;
}

const DesktopFallback: React.FC<DesktopFallbackProps> = ({
  title,
  instructions
}) => {
  return (
    <FallbackContainer>
      <h1 className="text-white text-center text-[clamp(3.75rem,5vw+1rem,6.25rem)] font-light tracking-tighter desktop-fallback-title-line-height">
      {title.split(' ').slice(0, title.split(' ').length / 2).join(' ')}<br />
      {title.split(' ').slice(title.split(' ').length / 2).join(' ')}
      </h1>
      <div className="grid gap-[22px]">
        <p className="text-white text-center text-[clamp(18px,2vw+1rem,20px)] font-light">
          {instructions}
        </p>
        <DesktopFallbackQRCode />
      </div>

      <MobileIntroLogos />
    </FallbackContainer>
  );
};

export default DesktopFallback;
