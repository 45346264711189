// VideoPlayer.tsx

import * as React from 'react';

interface VideoPlayerProps {
  videoBlob: Blob;
}

const VideoLoader = () => {
  return (
    <div className="absolute inset-0 z-2 bg-black  grid place-content-center">
      <span className="text-white font-serif text-2xl italic animate-pulse">
        Processing video
      </span>
    </div>
  );
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoBlob }) => {
  const videoUrl = URL.createObjectURL(videoBlob);
  const [isLoading, setIsLoading] = React.useState(true);

  function handleVideoEvent(evt: any) {
    if (evt.type === 'loadedmetadata') {
      console.log('hide loader');
      setIsLoading(false);
      return;
    }
    console.debug('videoEvent', evt);
  }

  return (
    <div className="relative h-full w-full bg-black">
      <video
        autoPlay
        loop
        className="absolute inset-0"
        muted
        height={window.innerHeight}
        width={window.innerWidth}
        webkit-playsinline="true"
        playsInline
        onLoadedMetadata={handleVideoEvent}
        onError={handleVideoEvent}
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {isLoading && <VideoLoader />}
    </div>
  );
};

export default VideoPlayer;
