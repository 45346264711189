import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';

export function useSentry() {
  const sentryInitialized = useRef(false);
  useEffect(() => {
    if (sentryInitialized.current === false) {
      Sentry.init({
        dsn: 'https://6b51c55561f643aed3a912aeafe42b1a@o1021155.ingest.us.sentry.io/4506877468606464',
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost']
          })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0 // Capture 100% of the transactions
      });
      sentryInitialized.current = true;
    }
  }, []);
}
