// MobileLoading.tsx

import React from 'react';
import './styles.css';
import MobileIntroLogos from './MobileIntroLogos';

import { FallbackContainer } from './FallbackContainer';

interface MobileLoadingProps {
  instructions: string;
}

const MobileLoading: React.FC<MobileLoadingProps> = ({instructions}) => {
  return (
      <FallbackContainer isMobile>
        <div className="spinner"></div>
      </FallbackContainer>
  );
};

export default MobileLoading;
