export function PoweredBySnapchat() {
  return (
    <div className="flex flex-nowrap gap-1 items-center fixed bottom-2 right-4">
      <div className="font-light text-[10px] h-full inline-block leading-[1] text-white/60 pb-[2px]">
        Powered by
      </div>
      <img
        alt="snapchat logo"
        src="./snapchat-ghost-logo.svg"
        height="14"
        width="14"
      />
    </div>
  );
}
