import * as React from 'react';
import { useRef, useState} from 'react';
import { ga_event } from '../Analytics';
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL, fetchFile } from "@ffmpeg/util";

export type CameraType = 'back' | 'front';

/**
 * provides tools to take a photo or record a video.
 * will re-initialize when camera is updated.
 */
export function useMediaRecorder({
  canvasRef,
  camera
}: {
  canvasRef: React.MutableRefObject<HTMLCanvasElement>;
  camera: CameraType;
}) {
  const [isRecording, setIsRecording] = React.useState(false);
  const [recording, setRecording] = React.useState<{
    blob: Blob;
    type: 'image' | 'video';
  } | null>(null);

  const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);
  const chunks = React.useRef<Blob[]>([]);
  const timeSlice = 500;

  const canvas = canvasRef.current;

  const [loaded, setLoaded] = useState(false);
  const ffmpegRef = useRef(new FFmpeg());

  const ffmpegLoad = async () => {
    const baseURL: string = `${process.env.PUBLIC_URL}/ffmpeg`;
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on("log", ({ message }) => {
      console.debug(message);
    });
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm"),
    });
    setLoaded(true);
    // console.log("1");
  };

  const transcode = async (videoURL:any) => {

    const ffmpeg = ffmpegRef.current;
    const sourceURL = await fetchFile(videoURL);
    // console.log("2");
    await ffmpeg.writeFile("input.mp4", sourceURL);
    // console.log("3");
    // with -codec copy it's not re-encoding, just moving the metadata, so way faster
    await ffmpeg.exec(["-i", "input.mp4", "-codec", "copy", "-movflags", "faststart", "output.mp4","-loglevel", "error"]);
    const fileData = await ffmpeg.readFile('output.mp4');
    
    const data = new Uint8Array(fileData as ArrayBuffer);

    return new Blob([data.buffer], { type: 'video/mp4' });

  };

  const isAndroidDevice = (): boolean => {
    console.log(navigator.userAgent);
    return /Android/i.test(navigator.userAgent);
  };

  const initializeMediaRecorder = React.useCallback(
    async ({
      camera,
      canvas
    }: {
      camera: CameraType;
      canvas: HTMLCanvasElement;
    }) => {
      console.log('initialize MediaRecorder', { camera });


      // improve performance on Android
      let captureStreamFPS;
      isAndroidDevice()? captureStreamFPS = 50 : captureStreamFPS = 60;

      
      const mediaStream = canvas.captureStream(captureStreamFPS);
      const micStream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });

      /** combine tracks into 1 MediaStream */
      const mixedStream = new MediaStream([
        ...mediaStream.getTracks(),
        ...micStream.getTracks()
      ]);


      let options = {};
      // improve performance on Android
      if (isAndroidDevice())
      {
        options = {
          audioBitsPerSecond: 128000,
          videoBitsPerSecond: 1000000,
        };
      }
      else{
        options = {
          audioBitsPerSecond: 128000,
          videoBitsPerSecond: 2500000,
        };
      }
      

      /** create MediaRecorder */
      const mediaRecorder = new MediaRecorder(mixedStream, options);
      mediaRecorder.onstart = () => {
        console.info('mediaRecorder: start', { camera });
        setIsRecording(true);
      };
      mediaRecorder.onstop = async (event) => {

        console.info('mediaRecorder: stop', { camera });

        setIsRecording(false);

        const mediaType = chunks.current.length > 1 ? 'video' : 'image';

        let blob: Blob;

        // handle video recording
        if (mediaType === 'video') {
          console.info('video was recorded', { camera });

          // we move the metadata at the beginning for both iOS/Android
          const tempBlob = new Blob(chunks.current, { type: 'video/mp4' });
          const videoUrl = URL.createObjectURL(tempBlob);
            
          blob = await transcode(videoUrl);

          // video length
          const videoLength = Math.round(timeSlice * chunks.current.length / 1000);
          // fire ga event
          console.info('video length: ', {videoLength});
          if (process.env.REACT_APP_ENV === 'development')
          {
            console.log("debugging recorded video event");
          }
          else
          {
            ga_event(`recorded-video-${videoLength}-${camera}`, {});
          }

          setRecording({
            blob,
            type: 'video'
          });
          chunks.current = [];
          return;
        }

        // handle image recording
        if (mediaType === 'image') {
          console.info('picture was taken', { camera });
          canvas.toBlob((canvasBlob) => {
            if (!canvasBlob) {
              console.warn('could not save image to blob');
              return;
            }

          // fire ga event
          console.info('image capture event');
          if (process.env.REACT_APP_ENV === 'development')
          {
            console.log("debugging captured image event");
          }
          else
          {
            ga_event(`captured-image-${camera}`, {});
          }

            setRecording({
              blob: canvasBlob,
              type: 'image'
            });
            chunks.current = [];
          });
        }
      };

      mediaRecorder.ondataavailable = (e) => {
        chunks.current.push(e.data);
      };

      /** set to ref so it is avail to other scopes */
      mediaRecorderRef.current = mediaRecorder;
    },
    []
  );

  /** initialize Media Recorder
   *  re-initialize when camera changes so we know the CameraType used.
   */
  React.useEffect(() => {
    if (!canvas) return;

    //load FFMPEG (both iOS and Android)
    ffmpegLoad();

    initializeMediaRecorder({ canvas, camera });
  }, [camera, canvas, initializeMediaRecorder]);

  const startRecording = React.useCallback(async () => {
    if (mediaRecorderRef.current?.state === 'recording') return;
    /** if a recording is already saved, clear it to start a new one */
    if (recording) setRecording(null);

    const mediaRecorder = mediaRecorderRef.current;
    /** add a timeslice value here to cut content into multiple chunks,
     *  each chunk is the amount of milliseconds provided.
     *  this way, if we only have 1 chunk, we know it is an image.
     */
    mediaRecorder?.start(timeSlice);
  }, [recording]);

  const stopRecording = React.useCallback(() => {
    mediaRecorderRef.current?.stop();
  }, []);

  return {
    start: startRecording,
    stop: stopRecording,
    isRecording,
    recording,
    clearRecording: () => {
      chunks.current = [];
      setRecording(null);
    }
  };
}
