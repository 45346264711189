// LandscapeFallback.tsx

import * as React from 'react';
import './styles.css';
import { FallbackContainer } from './FallbackContainer';

interface LandscapeFallbackProps {
  instructions: string;
}

const LandscapeFallback: React.FC<LandscapeFallbackProps> = ({
  instructions
}) => {
  return (
    <FallbackContainer noBg>
      <div className="grid gap-16 justify-items-center">
        <p className="font-sans text-white text-17 sm:text-22 font-light leading-5">
          {instructions}
        </p>
        <div className="landscape-fallback-image">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="58"
            height="39"
            viewBox="0 0 58 39"
            fill="none"
          >
            <path
              d="M46.3036 8.19388H49.6964M38.5 31.7653H57.5M40.5357 38.5H55.4643C56.5886 38.5 57.5 37.5954 57.5 36.4796V7.52041C57.5 6.40457 56.5886 5.5 55.4643 5.5H40.5357C39.4114 5.5 38.5 6.40457 38.5 7.52041V36.4796C38.5 37.5954 39.4114 38.5 40.5357 38.5ZM49.6964 35.1327C49.6964 36.0625 48.9369 36.8163 48 36.8163C47.0631 36.8163 46.3036 36.0625 46.3036 35.1327C46.3036 34.2028 47.0631 33.449 48 33.449C48.9369 33.449 49.6964 34.2028 49.6964 35.1327Z"
              stroke="white"
            />
            <path
              opacity="0.4"
              d="M3.19388 30.6964L3.19388 27.3036M26.7653 38.5L26.7653 19.5M33.5 36.4643L33.5 21.5357C33.5 20.4114 32.5954 19.5 31.4796 19.5L2.52041 19.5C1.40457 19.5 0.5 20.4114 0.5 21.5357L0.5 36.4643C0.5 37.5886 1.40457 38.5 2.52041 38.5L31.4796 38.5C32.5954 38.5 33.5 37.5886 33.5 36.4643ZM30.1327 27.3036C31.0625 27.3036 31.8163 28.0631 31.8163 29C31.8163 29.9369 31.0625 30.6964 30.1327 30.6964C29.2028 30.6964 28.449 29.9369 28.449 29C28.449 28.0631 29.2028 27.3036 30.1327 27.3036Z"
              stroke="white"
            />
            <path
              d="M25.4494 1.17618L29.5164 5.24321M29.5164 5.24321L25.534 9.22557M29.5164 5.24321L23.4814 5.36758C22.0392 5.35205 20.6242 5.70211 19.3686 6.3851C18.1129 7.06809 17.0574 8.06174 16.3007 9.27335C15.5439 10.485 15.1105 11.875 15.0409 13.3138"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </FallbackContainer>
  );
};

export default LandscapeFallback;
