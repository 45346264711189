import * as React from 'react';

export function useDeviceOrientation() {
  const [orientation, setOrientation] = React.useState<
    'portrait' | 'landscape'
  >(window.innerHeight > window.innerWidth ? 'portrait' : 'landscape');

  React.useEffect(() => {
    const handleResize = () => {
      const isPortraitNow = window.matchMedia(
        '(orientation: portrait)'
      ).matches;
      setOrientation(isPortraitNow ? 'portrait' : 'landscape');
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return {
    isPortrait: orientation === 'portrait',
    isLandscape: orientation === 'landscape',
    orientation: orientation
  };
}
