// DesktopFallbackQRCode.tsx

import * as React from 'react';
import './styles.css';

const DesktopFallbackQRCode: React.FC = () => {
  return (
    <div className="w-max mx-auto overflow-clip rounded-lg">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="128px" height="128px" viewBox="0 0 2277 2277" enable-background="new 0 0 2277 2277" xmlSpace="preserve">
<rect x="0" y="0" width="2277" height="2277" fill="rgb(255,255,255)" /><g transform="translate(138,138)"><g transform="translate(621,0) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,0) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(828,0) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,0) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1242,0) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1311,0) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(828,69) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,69) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,69) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,138) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,138) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1035,138) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1173,138) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1242,138) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(621,207) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,207) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(897,207) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,207) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1242,207) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1311,207) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,276) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(759,276) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(552,345) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,345) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1035,345) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,345) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1173,345) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1311,345) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,345) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(552,414) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,414) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(828,414) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,414) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,414) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1242,414) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,414) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(759,483) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(828,483) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(897,483) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1035,483) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,483) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1173,483) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1311,483) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,483) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,552) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(207,552) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(345,552) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(414,552) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(552,552) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(759,552) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(828,552) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,552) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,552) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1449,552) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1587,552) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,621) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(138,621) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(345,621) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(483,621) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(621,621) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,621) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(828,621) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(897,621) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1035,621) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,621) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,621) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1518,621) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1725,621) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1932,621) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(69,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(138,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(207,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(276,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(414,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(483,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(621,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(759,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(897,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1035,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1242,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1311,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1449,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1587,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1656,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1725,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1794,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1863,690) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,759) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(69,759) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(138,759) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(345,759) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,759) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(897,759) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,759) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1242,759) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1311,759) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1449,759) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1794,759) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1863,759) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(207,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(276,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(345,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(414,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(552,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1035,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1173,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1449,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1518,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1725,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1863,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1932,828) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(138,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(276,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(483,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(552,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(759,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(828,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(897,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1035,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1173,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1311,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1449,897) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(69,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(207,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(276,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(345,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(414,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(552,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(759,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(897,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1242,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1518,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1587,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1656,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1725,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1794,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1863,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1932,966) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,1035) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(69,1035) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(207,1035) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(276,1035) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(483,1035) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(759,1035) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1035,1035) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,1035) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1173,1035) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1242,1035) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1311,1035) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1725,1035) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1863,1035) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(69,1104) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(138,1104) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(276,1104) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(414,1104) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(483,1104) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(552,1104) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(897,1104) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1035,1104) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1173,1104) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,1104) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1449,1104) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1587,1104) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1863,1104) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(138,1173) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(207,1173) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(276,1173) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(345,1173) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(483,1173) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(828,1173) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(897,1173) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,1173) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,1173) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1449,1173) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1518,1173) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1587,1173) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1725,1173) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1932,1173) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,1242) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(276,1242) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(414,1242) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(552,1242) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(621,1242) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,1242) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1242,1242) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,1242) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1518,1242) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1587,1242) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1656,1242) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1863,1242) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1932,1242) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(207,1311) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(276,1311) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(621,1311) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,1311) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(759,1311) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,1311) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1173,1311) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1242,1311) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,1311) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1449,1311) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1863,1311) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1932,1311) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,1380) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(138,1380) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(207,1380) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(414,1380) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(621,1380) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(828,1380) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1035,1380) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,1380) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,1380) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1449,1380) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1518,1380) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1587,1380) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1656,1380) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1794,1380) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(552,1449) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(621,1449) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(759,1449) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,1449) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,1449) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,1449) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1656,1449) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1794,1449) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1863,1449) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1932,1449) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,1518) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,1518) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1173,1518) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,1518) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1518,1518) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1656,1518) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1863,1518) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(552,1587) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(621,1587) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,1587) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(828,1587) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,1587) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,1587) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1656,1587) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1725,1587) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1794,1587) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,1656) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,1656) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1173,1656) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,1656) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1449,1656) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1518,1656) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1587,1656) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1656,1656) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1932,1656) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(552,1725) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,1725) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(897,1725) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1173,1725) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1242,1725) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,1725) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1518,1725) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1587,1725) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1656,1725) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1725,1725) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1794,1725) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1863,1725) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,1794) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(828,1794) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,1794) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,1794) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1173,1794) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1311,1794) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,1794) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1656,1794) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1725,1794) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1794,1794) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1932,1794) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(690,1863) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(759,1863) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(897,1863) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,1863) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1104,1863) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1173,1863) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1311,1863) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,1863) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1449,1863) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1518,1863) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1863,1863) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(552,1932) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(828,1932) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(897,1932) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(966,1932) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1035,1932) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1173,1932) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1242,1932) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1311,1932) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1380,1932) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1449,1932) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1587,1932) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1725,1932) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1863,1932) scale(0.138,0.138)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,0) scale(4.83, 4.83)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<g>
	<path style={{ fill: 'none' }} d="M65.859,15.008H34.141c-4.082,0-7.869,1.258-10.979,3.398c-2.419,1.665-4.428,3.864-5.848,6.421
		C15.838,27.484,15,30.526,15,33.758v32.471c0,10.344,8.586,18.76,19.145,18.76L50,84.992l15.855-0.004
		C76.414,84.988,85,76.572,85,66.229V33.758C85,23.419,76.414,15.008,65.859,15.008z"/>
	<path d="M65.859,0.008H34.141h0C18.683,0.008,5.587,10.221,1.4,24.18c-0.433,1.444-0.771,2.928-1.006,4.445
		C0.135,30.299,0,32.013,0,33.758v32.471c0,18.619,15.32,33.76,34.141,33.76L50,99.992l15.859-0.004
		c18.82,0,34.141-15.141,34.141-33.76V33.758C100,15.148,84.68,0.008,65.859,0.008z M85,66.229c0,10.344-8.586,18.76-19.145,18.76
		L50,84.992l-15.855-0.004C23.586,84.988,15,76.572,15,66.229V33.758c0-3.231,0.838-6.273,2.313-8.931
		c1.42-2.557,3.429-4.756,5.848-6.421c3.11-2.141,6.897-3.398,10.979-3.398h31.719C76.414,15.008,85,23.419,85,33.758V66.229z"/>
</g>
</g></g><g transform="translate(1518,0) scale(4.83, 4.83)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<g>
	<path style={{ fill: 'none' }} d="M65.859,15.008H34.141c-4.082,0-7.869,1.258-10.979,3.398c-2.419,1.665-4.428,3.864-5.848,6.421
		C15.838,27.484,15,30.526,15,33.758v32.471c0,10.344,8.586,18.76,19.145,18.76L50,84.992l15.855-0.004
		C76.414,84.988,85,76.572,85,66.229V33.758C85,23.419,76.414,15.008,65.859,15.008z"/>
	<path d="M65.859,0.008H34.141h0C18.683,0.008,5.587,10.221,1.4,24.18c-0.433,1.444-0.771,2.928-1.006,4.445
		C0.135,30.299,0,32.013,0,33.758v32.471c0,18.619,15.32,33.76,34.141,33.76L50,99.992l15.859-0.004
		c18.82,0,34.141-15.141,34.141-33.76V33.758C100,15.148,84.68,0.008,65.859,0.008z M85,66.229c0,10.344-8.586,18.76-19.145,18.76
		L50,84.992l-15.855-0.004C23.586,84.988,15,76.572,15,66.229V33.758c0-3.231,0.838-6.273,2.313-8.931
		c1.42-2.557,3.429-4.756,5.848-6.421c3.11-2.141,6.897-3.398,10.979-3.398h31.719C76.414,15.008,85,23.419,85,33.758V66.229z"/>
</g>
</g></g><g transform="translate(0,1518) scale(4.83, 4.83)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<g>
	<path style={{ fill: 'none' }} d="M65.859,15.008H34.141c-4.082,0-7.869,1.258-10.979,3.398c-2.419,1.665-4.428,3.864-5.848,6.421
		C15.838,27.484,15,30.526,15,33.758v32.471c0,10.344,8.586,18.76,19.145,18.76L50,84.992l15.855-0.004
		C76.414,84.988,85,76.572,85,66.229V33.758C85,23.419,76.414,15.008,65.859,15.008z"/>
	<path d="M65.859,0.008H34.141h0C18.683,0.008,5.587,10.221,1.4,24.18c-0.433,1.444-0.771,2.928-1.006,4.445
		C0.135,30.299,0,32.013,0,33.758v32.471c0,18.619,15.32,33.76,34.141,33.76L50,99.992l15.859-0.004
		c18.82,0,34.141-15.141,34.141-33.76V33.758C100,15.148,84.68,0.008,65.859,0.008z M85,66.229c0,10.344-8.586,18.76-19.145,18.76
		L50,84.992l-15.855-0.004C23.586,84.988,15,76.572,15,66.229V33.758c0-3.231,0.838-6.273,2.313-8.931
		c1.42-2.557,3.429-4.756,5.848-6.421c3.11-2.141,6.897-3.398,10.979-3.398h31.719C76.414,15.008,85,23.419,85,33.758V66.229z"/>
</g>
</g></g><g transform="translate(138,138) scale(2.07, 2.07)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<g>
	<g id="XMLID_1_">
		<g>
			<path d="M27.351,100c-15.09,0-27.365-12.032-27.365-26.808V26.794c0-4.616,1.2-8.96,3.301-12.761
				c2.029-3.658,4.901-6.802,8.36-9.174C16.09,1.801,21.506,0,27.336,0h45.327c15.076,0,27.351,12.018,27.351,26.793v46.398
				c0,14.775-12.274,26.808-27.351,26.808H50H27.351z"/>
		</g>
		<g>
		</g>
	</g>
</g>
</g></g><g transform="translate(1656,138) scale(2.07, 2.07)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<g>
	<g id="XMLID_1_">
		<g>
			<path d="M27.351,100c-15.09,0-27.365-12.032-27.365-26.808V26.794c0-4.616,1.2-8.96,3.301-12.761
				c2.029-3.658,4.901-6.802,8.36-9.174C16.09,1.801,21.506,0,27.336,0h45.327c15.076,0,27.351,12.018,27.351,26.793v46.398
				c0,14.775-12.274,26.808-27.351,26.808H50H27.351z"/>
		</g>
		<g>
		</g>
	</g>
</g>
</g></g><g transform="translate(138,1656) scale(2.07, 2.07)"><g transform="" style={{ fill: 'rgb(0, 0, 0)' }}>
<g>
	<g id="XMLID_1_">
		<g>
			<path d="M27.351,100c-15.09,0-27.365-12.032-27.365-26.808V26.794c0-4.616,1.2-8.96,3.301-12.761
				c2.029-3.658,4.901-6.802,8.36-9.174C16.09,1.801,21.506,0,27.336,0h45.327c15.076,0,27.351,12.018,27.351,26.793v46.398
				c0,14.775-12.274,26.808-27.351,26.808H50H27.351z"/>
		</g>
		<g>
		</g>
	</g>
</g>
</g></g></g></svg>
	</div>

  );
};

export default DesktopFallbackQRCode;
