import * as React from 'react';
import { VideoBg } from './VideoBg';

function BackgroundImage({ url }: { url: string }) {
  return (
    <div className="absolute inset-0">
      <div
        className="relative h-full w-full bg-fixed bg-center bg-cover bg-black/50 before:content=[''] before:absolute before:inset-0 before:bg-black/70"
        style={{ backgroundImage: `url("${url}")` }}
      />
    </div>
  );
}

export function FallbackContainer({
  children,
  isMobile,
  noBg
}: {
  children: React.ReactNode;
  isMobile?: boolean;
  noBg?: boolean;
}) {
  return (
    <div className="h-full w-full relative b-black">
      {!noBg && <VideoBg />}
      <div className="relative h-full w-full overflow-y-auto grid">
        <div className="m-auto max-w-full sm:p-10 box-border">
          <div className="mx-auto text-center w-full sm:max-w-2xl grid gap-16 justify-items-center">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
