// ShareButton.tsx

import React from 'react';
import './styles.css';

interface ShareButtonProps {
  title: string;
  clickShareButton: () => void;
}

const ShareButton: React.FC<ShareButtonProps> = ({
  title,
  clickShareButton
}) => {
  return (
    <button className="share-button" onClick={clickShareButton}>
      <p className="share-button-text">Share {title}</p>
      <div className="share-button-arrow">
        <svg
          width="34"
          height="35"
          viewBox="0 0 34 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Arrow base">
            <path
              id="thick"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.9497 23.4497C21.3974 23.4497 20.9497 23.002 20.9497 22.4497L20.9497 14.9645L12.7573 23.1569C12.3668 23.5474 11.7337 23.5474 11.3431 23.1569C10.9526 22.7663 10.9526 22.1332 11.3431 21.7426L19.5355 13.5503L12.0502 13.5503C11.498 13.5503 11.0502 13.1025 11.0502 12.5503C11.0502 11.998 11.498 11.5503 12.0502 11.5503L21.9497 11.5503C22.502 11.5503 22.9497 11.998 22.9497 12.5503L22.9497 22.4497C22.9497 23.002 22.502 23.4497 21.9497 23.4497Z"
              fill="#1E1E1E"
            />
          </g>
        </svg>
      </div>
    </button>
  );
};

export default ShareButton;
